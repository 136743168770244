<template>
    <div class="panel-style">
        <el-form :inline="true" :model="searchDto" size="mini">
            <el-form-item label="归属机构">
                <doart-select-tree
                    :width="180"
                    :data="orgData"
                    clearable
                    @change="handleSelectTreeChangeEvent"/>
            </el-form-item>
            <el-form-item label="角色名称">
                <el-input v-model="searchDto.roleName" clearable autocomplete="off" placeholder="角色名称"/>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleQueryClickEvent">查询</el-button>
                <el-button type="primary" @click="openSysRoleDetailPage(null)">新增</el-button>
            </el-form-item>
        </el-form>
        <el-table border stripe size="small" :data="tableData" :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                  max-height="550px" v-loading="listLoading">
            <el-table-column prop="orgName" label="归属机构" align="left"/>
            <el-table-column prop="roleName" label="角色名称" align="left"/>
            <el-table-column prop="roleCode" label="角色编码" align="left"/>
            <el-table-column prop="roleType" label="角色类型" align="left" :formatter="roleTypeFormatter"/>
            <el-table-column prop="createTime" label="创建时间" align="center" width="150"/>
            <el-table-column prop="updateTime" label="更新时间" align="center" width="150"/>
            <el-table-column label="操作" align="center" width="80">
                <template slot-scope="scope">
                    <!-- 超级管理员不允许编辑和删除 -->
                    <div class="option-btn-div" v-if="scope.row.id !== 1">
                        <el-tooltip v-delTabIndex effect="dark" content="编辑" placement="top">
                            <em class="el-icon-edit option-btn-normal" @click="openSysRoleDetailPage(scope.row.id)"/>
                        </el-tooltip>
                        <el-tooltip v-delTabIndex effect="dark" content="删除" placement="top">
                            <em class="el-icon-delete option-btn-normal" style="color: #F56C6C" @click="removeSysRole(scope.row)"/>
                        </el-tooltip>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination">
            <el-pagination
                background
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableTotal"
                :current-page.sync="searchDto.pageNum"
                :page-sizes="[10, 50, 100]"
                :page-size="searchDto.pageSize"
                @size-change="handleSizeChangeEvent"
                @current-change="handleCurrentChangeEvent"/>
        </div>
        <sys-detail ref="sysRoleDetailPage" @querySysRoleList="querySysRoleList"/>
    </div>
</template>

<script>
    import NavBreadcrumb from '@/components/NavBreadcrumb.vue';
    import message from '@/utils/message';
    import {querySysRoleList, removeSysRole} from '@/api/sys/roleApi';
    import SysDetail from '@/views/sys/role/sys-role-detail';
    import global from '@/components/Global';
    import DoartSelectTree from '@/components/doart-select-tree/doart-select-tree';
    import {queryCurrentUserOrgTree}  from '@/api/sys/org/sysOrgApi';

    export default {

        name: 'SysList',
        components: {DoartSelectTree, SysDetail, NavBreadcrumb },
        data() {

            return {

                orgData: [],
                searchDto: {

                    pageNum: 1,
                    pageSize: 10,
                    roleName: '',
                    orgId: null
                },
                listLoading:false,
                tableData: [],
                tableTotal: 0,
                detailDialogVisible: false
            }
        },
        methods: {

            initSysListPage() {

                this.queryCurrentUserOrgTree();
                this.querySysRoleList();
            },
            handleCurrentChangeEvent(val) {

                this.searchDto.pageNum = val;
                this.querySysRoleList();
            },
            handleSizeChangeEvent(val) {

                this.searchDto.pageSize = val;
                this.querySysRoleList();
            },
            handleQueryClickEvent() {

                this.searchDto.pageNum = 1;
                this.querySysRoleList();
            },
            querySysRoleList() {

                this.listLoading = true;
                querySysRoleList(this.searchDto).then((res) => {

                    this.listLoading = false;
                    if (res.code === global.response_status_success_obj) {

                        this.tableTotal = res.data.total;
                        this.tableData = res.data.result;
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => {

                    message.error('请求失败,请稍后再试');
                    console.error(err);
                    this.listLoading = false;
                });
            },
            openSysRoleDetailPage(id) {

                this.$refs.sysRoleDetailPage.initSysDetailPage(id, this.orgData);
            },
            removeSysRole(row) {

                this.$confirm('是否需要删除？', '提示', {type: 'warning'})
                    .then(() => {

                        removeSysRole({id : row.id}).then((res) => {

                            if (res.code === global.response_status_success_obj) {

                                message.success('删除成功');
                            } else {

                                message.error(res.msg);
                            }
                            this.querySysRoleList();
                        }).catch(err => {

                            message.error('请求失败,请稍后再试');
                            console.error(err);
                        });
                    });
            },
            handleSelectTreeChangeEvent(selectedData) {

                this.searchDto.orgId = selectedData;
            },
            queryCurrentUserOrgTree() {

                queryCurrentUserOrgTree().then((res) => {

                    if (res.code === global.response_status_success_obj) {

                        this.orgData = [res.data];
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => {

                    console.error(err);
                    message.error(err);
                })
            },
            roleTypeFormatter(row, column, cellValue) {

                if (cellValue === 0) {

                    return '私有角色';
                } else if (cellValue === 1) {

                    return '公有角色';
                } else {

                    return '--';
                }
            }
        },
        mounted() {

            this.initSysListPage();
        }
    }
</script>

<style lang="scss" scoped>

    .pagination {

        margin-top: 15px;
        text-align: right;
    }
    .option-btn-div {

        display: flex;
        justify-content: space-around
    }
    .option-btn-normal {

        font-size: 18px;
        color: #409EFF;
        cursor: pointer;
    }
</style>
